.wrap-guide-popup {
  position: absolute;
  z-index: 1080;
  width: 70vw;
  background-color: #ffffff;
  max-height: 80vh;
  height: 75vh;
  top: 12vh;
  left: 15vw;
  overflow-y: auto;
  overflow-x: hidden;

  .popup-header {
    height: 119px;
    background-color: #000030;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 57px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.075em;
      color: #FFFFFF;
      margin: 0;
    }
  }

  .popup-body {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    .wrap-intro-description {
      position: relative;
      width: 50%;
      background-color: #F0E3D6;
      padding: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .intro-description {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
          text-align: center;
          font-size: 50px;
          font-family: "Graphik Arabic";
          font-style: normal;
          font-weight: 500;
          line-height: 90%;
          color: #C17250;
        }
        .description {
          width: 300px;
          text-align: center;
          font-size: 14px;
          font-family: "Graphik Arabic";
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
          color: #C17250;
          margin: 50px 0;
        }
        .btn-explore {
          cursor: pointer;
          span{
            color: #C17250;
            text-align: right;
            font-size: 14px;
            font-family: 'Graphik Arabic';
            font-style: normal;
            font-weight: 500;
            line-height: 90%;
          }
          img{
            margin-left: 40px;
          }
        }
      }
      .pattern{
        position: absolute;
        bottom: 50px;
        width: 100%;
        justify-content: center;
        display: flex;
      }
    }
    .wrap-intro-action {
      padding: 60px;
      background-color: #C17250;
      overflow-y: auto;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      .desktop {
        display: flex;
        flex-direction: column;
        .item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 75px;
          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            margin-bottom: 25px;
          }
          .text {
            text-align: center;
            font-size: 18px;
            font-family: 'Graphik Arabic';
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #F7F2EB;
            width: 120px;
          }
        }
        .item:last-child{
          margin-bottom: 0;
        }
      }
    }
    .intro-item {
      text-align: center;
    }
  }

  .close-btn {
    position: absolute;
    top: 40px;
    right: 49px;
    cursor: pointer;
  }

  .show-desktop{
    display: flex !important;
  }
  .show-mobile{
    display: none !important;;
  }
}

.wrap-intro-future-btn {
  position: absolute;
  z-index: 100;
  width: 320px;
  right: 93px;
  bottom: 176px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  .divider{
    width: 120px;
    height: 1px;
    background: #F7F2EB;
    margin-bottom: 20px;
  }
  span {
    text-align: right;
    font-size: 24px;
    font-family: "Graphik Arabic";
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
    color: #F7F2EB;
  }
}


@media (max-width: 1024px) {
  .wrap-guide-popup{
    .show-desktop{
      display: none !important;;
    }
    .show-mobile{
      display: flex !important;;
    }
  }
}
