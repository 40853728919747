.wrap-gallery-popup {
  position: absolute;
  z-index: 1070;
  width: 80vw;
  background-color: #ffffff;
  height: 75vh;
  top: 12vh;
  left: 10vw;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
    pointer-events: none;
  }

  .close-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    //.close-btn-icon {
    //  position: absolute;
    //  top: 15px;
    //  left: 15px;
    //}
  }

  .wrap-popup-content {
    position: relative;
    .wrap-360-btn {
      position: absolute;
      bottom: 29px;
      left: 100px;
      cursor: pointer;
      button {
        background: transparent;
        color: #000030;
        font-weight: bold;
        border: 3px solid #000030;
        font-size: 16px;
        width: 212px;
        padding: 0px;
        height: 40px;

        .btn-arrow {
          width: 40px;
          border-right: 3px solid #000030;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .btn-text {
          font-family: 'GT Walsheim Pro';
          color: #000030;
          font-weight: bold;
          font-size: 16px;
          text-decoration: none;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .popup-content {
    height: 100%;
    display: flex;
    .popup-header {
      height: 125px;
      background-color: #000030;
      display: flex;
      align-items: center;
      padding-left: 100px;

      .title {
        font-family: 'GT Walsheim Pro Light';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        letter-spacing: 0.075em;
        margin-bottom: 0px;
        color: #D4C1A7;
      }
    }

    .popup-body {
      position: relative;
      height: calc(100% - 125px);

      .description {
        font-family: 'PT Serif';
        height: 100%;
        padding-top: 91px;
        padding-left: 100px;
        padding-right: 59.43px;
        padding-bottom: 90px;
        overflow-y: auto;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        color: #000000;
        .description-text {
          height: 100%;
          overflow: auto;
        }
      }

      .images {
        height: 100%;
        position: relative;

        .wrap-gallery-image-popup {
          width: 100%;
          height: 100%;
          overflow: hidden;
          background-color: #000000;
          .slick-list {
            height: 100%;
            .slick-track {
              height: 100%;
              display: flex;
              .slick-slide>div {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .image-popup-item {
                overflow: hidden;
                img {
                  object-fit: contain;
                }
              }
            }
          }
        }

        .wrap-btn-next {
          z-index: 1001;
          position: absolute;
          bottom: 40px;
          right: 49.1px;
          cursor: pointer;
        }

        .wrap-btn-prev {
          z-index: 1001;
          position: absolute;
          bottom: 40px;
          right: 112.1px;
          cursor: pointer;
        }
      }
    }

    .content-left{
      width: 50%;
      background: #F7F2EB;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      span{
        max-width: 350px;
      }
      .title-content{
        text-align: center;
        font-size: 50px;
        font-family: "Graphik Arabic";
        font-style: normal;
        font-weight: 500;
        line-height: 90%;
        color: #C17250;
      }
      .description-content{
        color: #C17250;
        text-align: center;
        font-size: 14px;
        font-family: "Graphik Arabic";
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        margin: 50px 0;
      }
      .pattern{
        position: absolute;
        bottom: 50px;
      }
      .btn-explore {
        cursor: pointer;
        span{
          color: #C17250;
          text-align: right;
          font-size: 14px;
          font-family: 'Graphik Arabic';
          font-style: normal;
          font-weight: 500;
          line-height: 90%;
        }
        img{
          margin-left: 40px;
        }
      }
    }
    .content-right{
      width: 50%;
      height: 100%;
      position: relative;
      .wrap-gallery-image-popup {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: #000000;
        .slick-list {
          height: 100%;
          .slick-track {
            height: 100%;
            display: flex;
            .slick-slide>div {
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .image-popup-item {
              overflow: hidden;
              img {
                object-fit: cover;
              }
            }
          }
        }
      }

      .wrap-arrow-slider{
        position: absolute;
        bottom: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-arrow{
          margin: 0 2px;
          img{
            height: 16px;
            width: 19px;
          }
          .img-rotate{
            transform: rotate(180deg);
          }
        }
      }

      .wrap-btn-next {
        z-index: 1001;
        position: absolute;
        bottom: 40px;
        right: 49.1px;
        cursor: pointer;
        padding: 0 5px;
        border-radius: 4px;
        opacity: 0.8;
        img{
          transform: rotate(90deg);
        }
      }

      .wrap-btn-prev {
        z-index: 1001;
        position: absolute;
        bottom: 40px;
        right: 90px;
        cursor: pointer;
        padding: 0 5px;
        border-radius: 4px;
        opacity: 0.8;
        img{
          transform: rotate(270deg);
        }
      }
    }
  }
}

.bg-trim {
  position: absolute;
  z-index: 1070;
  background: rgba(0,0,0,0.5);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}
