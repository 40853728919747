.wrap-holding-page {
  .modal-form {
    color: #000;
    font-size: 14px;
    width: 636px;
    background: #fff;
    padding: 36px 40px;
    box-shadow: -4px 5px 4px 0 rgba(0, 0, 0, 0.25);
  }
  .modal-form__title {
    font-size: 26px;
    line-height: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    &.underline-sm {
      &::before {
        width: 75px;
        background: #000;
        bottom: -9px;
        height: 1px;
      }
    }
  }
  .modal-form__subtitle {
    font-size: 16px;
    line-height: 2.188em;
    margin-bottom: 26px;
  }
  .modal-form__body .form-group {
    padding-top: 13px;
    border-bottom: 1px solid #414345;
  }
  .modal-form__body .form-control {
    border-radius: 0;
    border: 0;
    height: 40px;
    padding: 6px 8px;
    line-height: 2;
    background: #fff;
    font-size: 14px;
    color: #414345;
    &:focus {
      box-shadow: none;
    }
  }
  .modal-form__body .btn-link {
    text-decoration: underline;
    font-size: 14px;
  }
}
