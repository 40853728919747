#loading-screen {
  position: absolute;
  z-index: 1060;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  opacity: 1;
  transition: 1s opacity;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background: #000000;

  #loading-cube-wrapper {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }

  #wrap-cube-model {
    position: absolute;
    top: 0px;
    z-index: 1;

    &.disable-click {
      pointer-events: none;
    }
  }

  .slick-dots {
    bottom: 50px;

    li {
      margin: 0 2px;

      button:before {
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 6px;
        border: 1px solid #fff;
        background-color: transparent;
        opacity: 1;
      }

      &.slick-active {
        button:before {
          background-color: #fff;
        }
      }
    }
  }

  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 0;
  }

  .slick-arrow {
    display: none !important;
  }

  .wrap-bg {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.center-center {
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    img {
      height: 100vh;
    }
  }

  .wrap-intro {
    &.hide {
      display: none;
    }

    &.show {
      display: unset;
    }
    .welcome-content{
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 100px 75px;
      .content-left{
        font-size: 150px;
        font-family: "Graphik Arabic";
        font-style: normal;
        font-weight: 500;
        line-height: 90%;
        color: #F0E3D6;
        width: 100px;
      }
      .content-right{
        display: flex;
        flex-direction: column;
        button{
          font-size: 24px;
          font-family: "Graphik Arabic";
          font-style: normal;
          font-weight: 500;
          line-height: 90%;
          border: none;
          background: transparent;
          color: #F0E3D6;
          margin-bottom: 20px;
          text-align: right;
          img{
            margin-left: 36px;
          }
        }
      }
      .disable-btn{
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  &.hide {
    display: none;
  }

  .intro-content {
    z-index: 1;
  }

  .wrap-slide-loading {
    transition: all 1s;

    .btn-skip-video {
      position: absolute;
      bottom: 80px;
      left: 45px;
      font-weight: 700;
      border-radius: 0px;
      font-size: 15px;
      line-height: 20.25px;
      background: #fff;
      width: 193px;
      height: 51px;

      @media only screen and (max-width: 1366px) {
        bottom: 150px;
      }
    }

    .wrap-content {
      position: absolute;
      bottom: 50px;
      left: 50px;
      text-align: left;

      .wrap-line {
        display: flex;
        border-top: 1px solid #fff;

        .left,
        .right {
          padding: 10px;
        }

        .left {
          width: 75%;
          border-right: 1px solid #fff;
        }

        .right {
          width: 25%;
          letter-spacing: 0.2rem;
        }
      }
    }

    &.hide {
      opacity: 0;
      display: none;
      pointer-events: none;
    }

    &.show {
      opacity: 1;
      pointer-events: unset;
    }
  }
}

#intro-video {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#intro-video-2 {
  object-fit: cover;
  width: 100vw;
  height: calc(100vh - 80px);
  position: fixed;
  top: 80px;
  left: 0;
}

#intro-img {
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  z-index: -1;
}

.intro-content {
  .wrap-text {
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 100;
    font-size: 60px;
    line-height: 69px;
    text-align: center;
    letter-spacing: 0.075em;
    color: #FFFFFF;
  }
  .wrap-button {
    height: 102px;
  }

  h1 {
    font-size: calc(2rem + 2vw);
    font-family: 'Philosopher', serif;
    margin-bottom: 1rem;
  }

  p {
    font-size: calc(1rem + 0.125vw);
    letter-spacing: 0.125rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
}

.wrap-text {
  margin-bottom: 3rem;
}

.wrap-button {
  button {
    background: transparent;
    color: #ffffff;
    font-weight: bold;
    border: 3px solid #ffffff;
    font-size: 16px;
    width: 262px;
    padding: 0px;
    height: 40px;

    .btn-arrow {
      width: 40px;
      border-right: 3px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .btn-text {
      color: #ffffff;
      font-weight: bold;
      font-size: 16px;
      text-decoration: none;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.wrap-loading-top-nav {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 80px;
  z-index: 100;
  background-color: #000030;

  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .wrap-logo {
      position: absolute;
      left: 0.75rem;
    }
  }
}

.wrap-btn-control-video {
  position: absolute;
  bottom: 75px;
  right: 0;
  z-index: 90;
  .btn-bordered {
    width: 187px !important;
  }
  button{
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 20px;
  }
}

.btn-bordered {
  background: transparent;
  color: transparent;
  font-weight: bold;
  border: none;
  font-size: 16px;
  width: 162px;
  padding: 0px;
  height: 40px;
  right: 50px;
  bottom: -28px;
  font-family: 'GT Walsheim Pro';
  border: 3px solid #ffffff;
  .btn-arrow {
    width: 44px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 3px solid #ffffff;
  }
  .btn-arrowb {
    width: 44px;
    border-right: 3px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-text {
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: #ffffff;
    }
  }
  &.btn-black {
    border-color: #000000;
    background-color: #ffffff;
    .btn-arrow {
      border-right: 3px solid #000000;
      width: 44px;
      .img-fluid {
        width: 20px;
        height: 22px;
      }
    }
    .btn-arrowb {
      border-right: 3px solid #000000;
      background-color: #000000;
      width: 44px;
      .img-fluid {
        width: 20px;
        height: 22px;
      }
    }
    .btn-text {
      color: #000000;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrap-btn-tour {
    .btn-guided-tour {
      margin-right: 0px !important;
      margin-bottom: 1rem;
    }
  }
}
