.wrap-button {
  height: 102px;
}
.btn-future {
  width: 232px;
  .btn-arrow {
    background-color: #000000 !important;
  }
  .btn-text {
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    margin-top: 1px;
  }
}
.btn-current {
  width: 232px;
  .btn-text {
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    margin-top: 1px;
  }
}
h1 {
font-size: calc(2rem + 2vw);
font-family: 'Philosopher', serif;
margin-bottom: 1rem;
}

p {
font-size: calc(1rem + 0.125vw);
letter-spacing: 0.125rem;
font-weight: 400;
margin-bottom: 0.5rem;
}
