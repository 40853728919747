.dim-bg {
    background-color: #2020207F;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
}

.main-modal {
    background-color: #ffffff;
    height: calc(100vh - 80px);
    width: 100vw;
    position: absolute;
    top: calc(80px);
    left: 0;
    z-index: 1000;
    text-align: center;
    display: inline-block;
    justify-content: center;
    align-items: center;

    .bg-image {
        background-color: #ffffff;
        width: 100%;
        height: calc(100% - 0px);
        top: 0px;

        .img-fluid {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        .img-panorama {
            width: 100%;
            height: 100%;
        }

        .btn-square {
            width: 40px;
            height: 40px;
            padding: 0;
            border: 0;
            position: absolute;
            top: 40px;
            right: 40px;
            cursor: pointer;
            z-index: 100;
            background-color: #F42F30;
        }

        .btn-next-pano {
            position: absolute;
            top: 60%;
            left: 40px;
            width: 116px;
            height: 73px;
            border: none;
            background-color: transparent;
            display: block;

            .btn-text2 {
                background-color: transparent;
                color: #ffffff;
                font-weight: bold;
                font-size: 16px;
                text-decoration: none;
                width: 100%;
                &:hover {
                color: #ffffff;
                }
                bottom: 0px;
                position: absolute;
                left: 0px;
            }

            .btn-square {
                width: 40px;
                height: 40px;
                padding: 0;
                border: 0;
                top: 0px;

                cursor: pointer;
                z-index: 100;
                background-color: #F42F30;

                .img-icon {
                    width: 14px;
                    height: 14px;
                    bottom: 4px;
                }
            }
        }

        .btn-next-pano2 {
            position: absolute;
            top: 60%;
            right: 40px;
            width: 116px;
            height: 73px;
            border: none;
            background-color: transparent;
            display: block;

            .btn-text2 {
                background-color: transparent;
                color: #ffffff;
                font-weight: bold;
                font-size: 16px;
                text-decoration: none;
                width: 100%;
                &:hover {
                color: #ffffff;
                }
                bottom: 0px;
                position: absolute;
                left: 0px;
            }

            .btn-square {
                width: 40px;
                height: 40px;
                padding: 0;
                border: 0;
                top: 0px;

                cursor: pointer;
                z-index: 100;
                background-color: #F42F30;

                .img-icon {
                    width: 14px;
                    height: 14px;
                    bottom: 4px;
                }
            }
        }

        .dots-container {
          width: 100%;
          background: transparent;
          pointer-events: none;
          display: flex;
          justify-content: center;
          .wrap-dots {
            pointer-events: all;
            position: absolute;
            z-index: 300;
            bottom: 40px;
            display: flex;
            flex-direction: row;
            .dot-item {
              width: 20px;
              height: 20px;
              background-color: transparent;
              border-radius: 50%;
              border: 3px solid #B8B5B8;
              margin-right: 20px;
              cursor: pointer;
              &:last-child {
                margin: 0px;
              }
              &.active {
                background-color: #ffffff;
                border-color: #ffffff;
              }
            }
          }
        }

        .grid-container {
            display: grid;
            grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
            position: absolute;
            left: 10%;
            bottom: 80px;
            border: none;
            width: 80%;
            height: 74px;
            justify-content: center;
            align-items: center;
        }

        .grid-item {
            border: none;
            padding: 10px;
            font-size: 30px;
            text-align: center;

            .grid-item-image {
                background-color: #ffffff;
                object-fit: cover;
                width: 109px;
                height: 74px;
            }

            .grid-item-text {
                background-color: transparent;
                color: #ffffff;
                font-weight: bold;
                font-size: 16px;
                text-decoration: none;
                &:hover {
                color: #ffffff;
                }
            }
          }

        .btn-move-next {
            right: 40px;
            top: 60%;
            .img-icon {
                width: 14px;
                height: 14px;
                bottom: 4px;
            }
        }
    }

    .black-content {
        background-color: #15131C;
        height: 15px;
        width: 100%;
        top: calc(100% - 15px);
    }

}
