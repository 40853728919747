html,
body {
  margin: 0;
  padding: 0;
  background: #272730;
  overflow: hidden;
}

body {
  font-family: 'GT Walsheim Pro';
  font-size: 34px;
  color: white;
  background: #f0f0f0;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

canvas {
  position: absolute;
}

.hotspot-parent {
  cursor: pointer;
  pointer-events: none;
  z-index: 1000;
  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.hotspot-content {
  transform: translate(-50%, -100%);
  cursor: pointer;
  pointer-events: all;
  background: transparent;

  z-index: 10;

  -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */

  .hotspot-grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 20px;
    justify-content: space-between;
  }

  .hotspot-content-item {
    //transform: translate(-50%, -100%);
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;
    padding: 0;

    .hotspot-title {

      color: #ffffff;
      pointer-events: none;
      font-family: 'Graphik Arabic';
      font-size: 40px;
      letter-spacing: 0.075em;
      color: #ffffff;
      border: none;
      background-color: transparent;
      text-overflow: ellipsis;
      /* Needed to make it work */
      overflow: hidden;
      white-space: nowrap;

      @media not all and (min-resolution:.001dpcm)
      { @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        position: relative;
        top: 5%;
        left: 0%;
        bottom: 30px;
      }}

        position: relative;
        text-align: center;
        align-items: center;
    }
  }

  .hotspot-img-container {

    .hotspot-img-fluid {
      position: relative;
      object-fit: cover;
      width: 10px;
      height: 110px;
      left: calc(50% - 11px);
    }
  }

}
