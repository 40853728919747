.wrap-contact-page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  * {
    font-family: "GT Walsheim Pro", sans-serif;
  }

  .contact-header {
    padding-top: 155px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 115%;
      letter-spacing: 0.075em;
      color: #15131C;
      margin-bottom: 20px;
    }
    p {
      font-family: 'PT Serif';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 34px;
      letter-spacing: -0.04em;
      color: #000000;
    }
  }
  .contact-form {
    padding-top: 20px;
    overflow: visible;
    .group-input {
      margin-bottom: 25px;
      &.error {
        input, textarea {
          border: 1px solid #F42F30;
        }
      }
      .error-text {
        font-family: 'GT Walsheim Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 34px;
        color: #F42F30;
      }
    }
    .btn-submit-enquiry {
      border: 3px solid #F42F30;
      width: 275px;
      padding: 5px 0px;
      font-weight: bold;
      color: #F42F30;
      background-color: #ffffff;
    }
  }
  label {
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 34px;
  }
  .form-control {
    font-family: 'GT Walsheim Pro Light';
    border-radius: 0px;
    font-style: normal;
    font-weight: 100;
    font-size: 15px;
    line-height: 34px;
  }
}
.mb-10 {
  margin-bottom: 10px;
}
.me-10 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px;
}
