.wrap-legend-sidebar {
  position: absolute;
  z-index: 900;
  top: 154px;
  left: 0;
  width: 310px;
  max-height: calc(100vh - 174px);
  overflow-y: scroll;
  padding: 30px 30px 50px 30px;
  background: #F7F2EB;
  .header-popup{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .card-title {
      font-size: 18px;
      font-family: "Graphik Arabic";
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: #C17250;
      margin: 0;
    }
    .divider{
      width: 35px;
      height: 1px;
      background: #C17250;
      margin: 25px 0;
    }
  }
  .card-body {
    padding: 0;
    .card {
      &.card-item {
        margin-bottom: 20px;
        border: none;
        background: transparent;
        .category-name {
          display: flex;
          flex-direction: row;
          align-items: center;
          .color-key {
            width: 15px;
            height: 15px;
            margin-right: 10px;
          }
          span{
            margin-left: 10px;
            text-transform: uppercase;
            font-size: 14px;
            font-family: "Graphik Arabic";
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            color: #C17250;
          }
        }
      }

      .list-group {
        list-style-type: none;

        .list-group-item {
          padding: 0;
          opacity: 1;
          background: transparent;
          border: none;
          &.heading {
            border: none;
          }
          &.no-selected-item {
            color: #FFFFFF;
          }
          &.has-selected-item {
            color: #B8B5B8;
          }
          &.heading-active {
            //border-top: 1px solid;
            //font-family: 'GT Walsheim Pro';
            //font-style: normal;
            //font-weight: 500;
            //font-size: 18px;
            //line-height: 21px;
            //letter-spacing: -0.01em;
            //color: #FFFFFF;
            //padding: 3px 0px;
            //opacity: 1;
          }
        }

        .wrap-list-item {
          list-style-type: none;
          padding: 0px;

          li {
            font-size: 14px;
            font-family: 'Graphik Arabic';
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            cursor: pointer;
            color: #C17250;
            margin: 15px 0;
            span{
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  .card-footer {
    padding: 0px;
    display: flex;
    justify-content: center;

    .list-group {
      width: 260px;
      background-color: #FFFFFF;
      border-radius: 0px;
      padding: 10px 22px;
      border-bottom: 5px solid #000030;

      .list-group-item {
        padding: 0px;
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 34px;
        display: flex;
        align-items: center;
        color: #000030;
        display: flex;
        align-items: center;
        flex-direction: row;
        .color {
          width: 15px;
          height: 15px;
          margin-right: 17px;
          &.existing-dev {
            background-color: #000030;
          }
          &.park-recreate {
            background-color: #A1B0D9;
          }
          &.future-dev {
            background-color: #F0C99E;
          }
          &.walking-tracks {
            background-color: #63E3A6;
          }
          &.mountain-bike-trails {
            background-color: #DF8988;
          }
        }
      }
    }
  }
  .pattern{
    position: absolute;
    bottom: 15px;
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
