.wrap-dashboard-page {
  min-height: 100vh;
  padding-bottom: 50px;
  background: #ffffff;
  .wrap-top-nav {
    position: sticky;
  }
  .card {
    border-radius: 0px;
  }
  .btn-customer-action {
    text-decoration: underline;
    text-transform: uppercase;
  }
}
