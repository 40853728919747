.wrap-reset-password-page {
  min-height: 100vh;
  background: #ffffff;
  .wrap-top-nav {
    position: unset;
  }
  .wrap-login-form {
    padding: 20px;
    .login-form {
      margin: 0 auto;
      h3 {
        margin-bottom: 20px;
      }
      .form-group {
        margin-bottom: 20px;
        .form-control {
          border-radius: 0px;
        }
      }
      .wrap-btn-action {
        display: flex;
        .login {
          flex-grow: 1;
          display: flex;
          align-items: center;
          small {
            text-decoration: underline;
            font-size: 12px;
          }
        }
        .btn-login {
          flex-grow: 0;
          margin: 0px;
        }
      }
    }
  }
}
