$animDuration: 1.5s;
$edgeColor: #fff;
$cubeSize: 26px;
$borderSize: $cubeSize/8;
$animationType: ease;
$shadowSize: $borderSize/2;

.cube-scene {
  width: $cubeSize * 2;
  height: $cubeSize * 2;
  perspective: 1000px;
  transition: top 0.2s;
  position: relative;
  cursor: grab;

  &.active {
    transition: none;
  }
}

.cube {
  width: $cubeSize;
  height: $cubeSize;
  left: $cubeSize/2;
  position: absolute;
  z-index: 2;
  transform: rotateX(-28deg) rotateY(45deg);
  transform-style: preserve-3d;

  &.disable-click {
    pointer-events: none;
  }
}

.cube__face {
  width: $cubeSize;
  height: $cubeSize;
  margin-top: $cubeSize/2;
  position: absolute;
  background: rgba(black, 0.1);
  border-color: $edgeColor;
  backface-visibility: hidden;
  border-radius: 3px;
}

.cube__face--top {
  border-top: $borderSize solid $edgeColor;
  border-right: $borderSize solid $edgeColor;
  border-bottom: $borderSize solid $edgeColor;
  border-left: $borderSize solid $edgeColor;
  transform: rotateX(90deg) translateZ($cubeSize/2-1);
}

.cube__face--front {
  border-left: $borderSize/1.5 solid $edgeColor;
  border-right: $borderSize solid $edgeColor;
  border-bottom: $borderSize solid $edgeColor;
  border-top: $borderSize/2 solid $edgeColor;
  transform: rotateY(0deg) translateZ($cubeSize/2-1);
}

.cube__face--right {
  border-left: $borderSize solid $edgeColor;
  border-right: $borderSize/2 solid $edgeColor;
  border-bottom: $borderSize solid $edgeColor;
  transform: rotateY(90deg) translateZ($cubeSize/2-1);
}

.cube__face--back {
  border-left: $borderSize/2 solid $edgeColor;
  border-right: $borderSize/2 solid $edgeColor;
  border-bottom: $borderSize solid $edgeColor;
  transform: rotateY(180deg) translateZ($cubeSize/2-1);
}

.cube__face--left {
  border-left: $borderSize solid $edgeColor;
  border-right: $borderSize solid $edgeColor;
  border-bottom: $borderSize solid $edgeColor;
  border-top: $borderSize/2 solid $edgeColor;
  transform: rotateY(-90deg) translateZ($cubeSize/2-1);
}

.cube__face--bottom {
  transform: rotateX(-90deg) translateZ($cubeSize/2);
}

.reverse {
  animation: cube-reverse $animDuration $animationType;
  .cube__face--front {
    animation: cube-face-front-reverse $animDuration $animationType;
  }
  .cube__face--right {
    animation: cube-face-right-reverse $animDuration $animationType;
  }
  .cube__face--back {
    animation: cube-face-back-reverse $animDuration $animationType;
  }
  .cube__face--left {
    animation: cube-face-left-reverse $animDuration $animationType;
  }
}

.forward {
  animation: cube-forward $animDuration $animationType;

  .cube__face--front {
    animation: cube-face-front-forward $animDuration $animationType;
  }

  .cube__face--right {
    animation: cube-face-right-forward $animDuration $animationType;
  }

  .cube__face--back {
    animation: cube-face-back-forward $animDuration $animationType;
  }

  .cube__face--left {
    animation: cube-face-left-forward $animDuration $animationType;
  }

  .cube__face--top {
    border: $borderSize solid $edgeColor;
  }
}

@keyframes cube-reverse {
  0% {
    transform: rotateX(-28deg) rotateY(45deg);
  }
  100% {
    transform: rotateX(-28deg) rotateY(-315deg);
  }
}

@keyframes cube-forward {
  0% {
    transform: rotateX(-28deg) rotateY(45deg);
  }
  100% {
    transform: rotateX(-28deg) rotateY(405deg);
  }
}

@keyframes cube-face-front-reverse {
  10% {
    border-right: $borderSize solid $edgeColor;
    border-left: $borderSize/2 solid $edgeColor;
  }
  20% {
    border-right: $borderSize/2 solid $edgeColor;
    border-left: $borderSize solid $edgeColor;
  }
  80% {
    border-right: $borderSize solid $edgeColor;
    border-left: $borderSize/2 solid $edgeColor;
  }
}

@keyframes cube-face-right-reverse {
  15% {
    border-right: $borderSize solid $edgeColor;
    border-left: $borderSize/2 solid $edgeColor;
  }
  40% {
    border-right: $borderSize/2 solid $edgeColor;
    border-left: $borderSize solid $edgeColor;
  }
}

@keyframes cube-face-back-reverse {
  30% {
    border-right: $borderSize solid $edgeColor;
    border-left: $borderSize/2 solid $edgeColor;
  }
  50% {
    border-right: $borderSize/2 solid $edgeColor;
    border-left: $borderSize solid $edgeColor;
  }
}

@keyframes cube-face-left-reverse {
  55% {
    border-right-width: $borderSize;
    border-left-width: $borderSize/2;
  }
  80% {
    border-right-width: $borderSize/2;
    border-left-width: $borderSize;
  }
}

@keyframes cube-face-front-forward {
  55% {
    border-left-width: $borderSize;
    border-right-width: $borderSize/2;
  }
  80% {
    border-left-width: $borderSize/2;
    border-right-width: $borderSize;
  }
}

@keyframes cube-face-right-forward {
  30% {
    border-left: $borderSize solid $edgeColor;
    border-right: $borderSize/2 solid $edgeColor;
  }
  50% {
    border-left: $borderSize/2 solid $edgeColor;
    border-right: $borderSize solid $edgeColor;
  }
}

@keyframes cube-face-back-forward {
  15% {
    border-left: $borderSize solid $edgeColor;
    border-right: $borderSize/2 solid $edgeColor;
  }
  40% {
    border-left: $borderSize/2 solid $edgeColor;
    border-right: $borderSize solid $edgeColor;
  }
}

@keyframes cube-face-left-forward {
  10% {
    border-left: $borderSize solid $edgeColor;
    border-right: $borderSize/2 solid $edgeColor;
  }
  20% {
    border-left: $borderSize/2 solid $edgeColor;
    border-right: $borderSize solid $edgeColor;
  }
  80% {
    border-left: $borderSize solid $edgeColor;
    border-right: $borderSize/2 solid $edgeColor;
  }
}
