.wrap-about {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  padding-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  * {
    font-family: "GT Walsheim Pro", sans-serif;
  }

  .about-header {
    padding-top: 155px;
    margin-bottom: 75px;
    h1 {
      margin-bottom: 75px;
      font-family: 'GT Walsheim Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 115%;
      letter-spacing: 0.075em;
      color: #15131C;
    }
    p {
      font-size: 20px;
      line-height: 34px;
      font-weight: 400;
      font-family: 'PT Serif';
      font-style: normal;
      letter-spacing: -0.04em;
      color: #000000;
    }
  }
  .about-content {
    .wrap-image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: relative;
      min-height: 300px;
    }
    .wrap-text {
      padding: 50px;
      h1 {
        font-family: 'GT Walsheim Pro Light';
        font-style: normal;
        font-size: 35px;
        line-height: 115%;
        letter-spacing: 0.075em;
        color: #15131C;
      }
      p {
        font-family: 'PT Serif';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 34px;
        margin-bottom: 15px;
      }
    }
  }

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 15px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f100;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #88888850;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #55555550;
  }
}
.bg-gray {
  background-color: #F9F8FA;
}
