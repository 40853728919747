.wrap-404-page {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  .wrap-desciption {
    text-align: center;
    text-transform: uppercase;
    h1 {
      font-size: 60px;
      font-weight: bold;
      font-family: 'Centra No1 TRIAL', sans-serif;
    }
  }
}
