$cubeSpacing: 100px;

.wrap-top-nav {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1070;
  background-color: #C17250;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header-logo{
    width: 100%;
    height: 80px;
    background: #C17250;
    justify-content: center;
    align-items: center;
    display: flex;
    img{
      cursor: pointer;
    }
  }
  .wrap-logo {
    position: absolute;
    height: 54px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .logo{
    cursor: pointer;
  }

  * {
    font-size: 14px;
  }

  span {
    color: #F0E3D6;
    text-decoration: none;
    display: block;
    padding: 20px 12px;
    cursor: pointer;
    font-size: 14px;
    font-family: "Graphik Arabic";
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &:hover {
      color: #fff;
    }
  }

  .menu-img {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    overflow: hidden;
    display: inline-block;

    img {
      width: 100%;
    }
  }

  .nav-menu {
    width: 100%;
    position: relative;
    pointer-events: auto;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    border-top: 1px solid #F0E3D6;
    max-width: inherit !important;

    &.disabled .left li,
    &.disabled .right li {
      pointer-events: none;
    }

    .left {
      text-align: right;
      width: calc(45% - #{$cubeSpacing / 2});
      transform: translateX(75%);
      z-index: 1;
      li:first-of-type {
        transform: translateX(50%);
      }

      li:last-of-type {
        transform: translateX(-50%);
      }
    }

    .nav-cube {
      width: $cubeSpacing;
      text-align: center;
      pointer-events: auto;
      z-index: 2;

      & > div {
        display: inline-block;
      }
    }

    .right {
      text-align: left;
      transform: translateX(-75%);
      width: calc(45% - #{$cubeSpacing / 2});
      z-index: 1;

      li:first-of-type {
        transform: translateX(50%);
        width: 30% !important;
      }
      li:last-of-type {
        transform: translateX(-50%);
        width: 30% !important;
      }
    }

    ul {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      transition: all 0.5s ease-in-out;
      vertical-align: middle;

      /* 3 items */
      li:first-child:nth-last-child(3),
      li:first-child:nth-last-child(3) ~ li {
        width: 38%;
      }

      /* 2 items */
      li:first-child:nth-last-child(2),
      li:first-child:nth-last-child(2) ~ li {
        width: 38%;
      }

      li {
        color: #fff;
        display: inline-block;
        list-style-type: none;
        opacity: 0;
        position: relative;
        text-align: center;
        transition: opacity 0.3s ease-in-out, transform 0.5s ease-in-out;
        user-select: none;
        vertical-align: middle;

        // &:not(.placeholder) span {
        //   font-weight: bold;
        //   position: relative;

        //   &::before {
        //     content: '';
        //     height: 1px;
        //     width: 0;
        //     background: #FFDB00;
        //     position: absolute;
        //     bottom: 0;
        //     opacity: 0;
        //     transition: all 0.2s;
        //   }
        // }
      }

      // li.active {
      //   span {
      //     &::before {
      //       opacity: 1;
      //       width: 97px;
      //     }
      //   }
      // }
    }
    .btn-go-back-virtual{
      position: absolute;
      left: 75px;
      bottom: 0;
      padding: 0;
      border: 0;
      background: transparent;
      display: flex;
      flex-direction: revert;
      justify-content: center;
      align-items: center;
      z-index: 99;
      img{
        margin-right: 30px;
        transform: rotate(180deg);
        width: 7px;
        height: 10px;
      }
      span{
        text-align: right;
        font-size: 14px;
        font-family: "Graphik Arabic";
        font-style: normal;
        font-weight: 500;
        line-height: 90%;
        color: #F0E3D6;
        text-transform: uppercase;
      }
    }
  }
}

.wrap-top-nav.show {
  .nav-menu {
    ul {
      transform: translateX(0) !important;

      li {
        opacity: 1;
        transform: translateX(0) !important;
        transition: opacity 0.5s ease-in, transform 0.5s ease-in-out;

        &:hover {
          span {
            &::before {
              width: 97px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
