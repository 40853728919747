.wrap-video-intro {
  position: absolute;
  top: 138px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  background: #000000;
  video{
    object-fit: cover;
    width: 100vw;
    height: calc(100vh - 138px);
    position: fixed;
    top: 138px;
    left: 0;
  }
  .wrap-btn-control-video {
    bottom: 213px;
  }
}
