.wrap-virtual-gallery {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #F7F2EB;
  padding-top: 138px;
  .virtual-gallery-content {
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 15px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f100;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #88888850;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #55555550;
    }
  }
  .virtual-gallery-header {
    padding-top: 74px;
    h1 {
      margin-bottom: 160px;
      font-size: 50px;
      font-family: "Graphik Arabic";
      font-style: normal;
      font-weight: 500;
      line-height: 90%;
      color: #C17250;
    }
  }
  .virtual-gallery-body {
    .virtual-item {
      h3 {
        font-size: 18px;
        font-family: "Graphik Arabic";
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: #C17250 ;
        padding-bottom: 25px;
        border-bottom: 1px solid  #C17250;
        margin-bottom: 25px;
        width: fit-content;
      }
    }
  }
}
